<template>
  <span>
    <font-awesome-icon :icon="['fas','circle']" :style="{ color: color}" />
    {{ this.status }}
  </span>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'TaskAlertStatusCellRenderer',
  data() {
    return {
      status: null,
      color: null
    }
  },
  created() {
    this.status = this.params.value;
    this.color = this.params.data.color;
  },
  methods: {
  }
});
</script>
