var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("font-awesome-icon", {
        style: { color: _vm.color },
        attrs: { icon: ["fas", "circle"] },
      }),
      _vm._v(" " + _vm._s(this.status) + " "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }