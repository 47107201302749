<template>  
    <ag-grid-vue  class="ag-grid ag-theme-balham"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          :columnDefs="columnDefs"
          :context="context"
          :defaultColDef="defaultColDef"
          pagination
          :paginationPageSize="100"
          :rowData="rowData"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :sideBar="false"
          suppressDragLeaveHidesColumns
          suppressCellFocus
          suppressScrollOnNewData
          suppressContextMenu
          suppressMultiSort
          >
    </ag-grid-vue>
</template>

<script>
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import TaskAlertStatusCellRenderer from '@/components/Aggrid/CellRenderer/TaskAlertStatus';
import DateTimeCellRenderer from '@/components/Aggrid/CellRenderer/DateTime';
import PercentageCellRenderer from '@/components/Aggrid/CellRenderer/Percentage';
import LinkCellRenderer from '@/components/Aggrid/CellRenderer/Link';

export default {
  name: 'TaskAlertList',
  components: {
    'ag-grid-vue': AgGridVue,
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    taskAlertStatusCellRenderer: TaskAlertStatusCellRenderer,
    dateTimeCellRenderer: DateTimeCellRenderer,
    percentageCellRenderer: PercentageCellRenderer,
    linkCellRenderer: LinkCellRenderer,
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    tasks: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    tasks: function(newValue) {
      if(this.gridApi) {
        this.rowData = newValue;
        this.gridApi.redrawRows();
      }
    }
  },
  computed: {
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('task_alert.grid.no_data') }</span>`;
    }
  },
  data: function() {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
    };
  },
  beforeMount() {
    const self = this;
    this.gridOptions = {
      onGridSizeChanged: function(event) {
        self.gridColumnApi.autoSizeColumn('name', false);
      }
    };
    this.columnDefs = [
      {
        headerName: this.$t('task_alert.grid.status'),
        field: 'status',
        maxWidth: 150,
        cellRenderer: 'taskAlertStatusCellRenderer'
      },
      {
        headerName: this.$t('task_alert.grid.name'),
        field: 'name',
        flex: 2,
        cellRenderer: 'linkCellRenderer',
        cellRendererParams: {
          value: function() {
            return this.data.name;
          },
          clicked: function(field) {
            // Loading indicator will be hidden when the grid is refreshed
            self.$emit('editTask', {
              taskId: this.data.uuId,
              projectId: this.data.projectId,
              callback: null
            });
          },
        },
      },
      {
        headerName: this.$t('task_alert.grid.start'),
        field: 'start',
        flex: 1,
        cellRenderer: 'dateTimeCellRenderer'
      },
      {
        headerName: this.$t('task_alert.grid.end'),
        field: 'end',
        flex: 1,
        cellRenderer: 'dateTimeCellRenderer'
      },
      {
        headerName: this.$t('task_alert.grid.progress'),
        field: 'progress',
        minWidth: 50,
        maxWidth: 100,
        cellRenderer: 'percentageCellRenderer'
      },
    ];
    this.defaultColDef = {
      resizable: true,
      minWidth: 150,
      menuTabs: []
    };
    this.context = {
      componentParent: self
    };
  },
  methods: {
    onGridReady(/*params*/) {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      this.rowData = this.tasks;
    },
  }
}
</script>
