<template>
  <WidgetFrame>
    <template v-slot:title>
      {{ $t('permission.active_tasks') }}
    </template>
    <template v-slot:content>
      <div v-if="!canView('PROJECT', ['TASK'])" class="center-text">
        {{ $t('entity_selector.error.insufficient_permission_to_show_data') }}
      </div>
      <template v-else-if="!loaded">
        <div class='status-message'>Loading...</div>
      </template>
      <template v-else>
        <ag-grid-vue class="ag-grid ag-theme-balham"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          :columnDefs="columnDefs"
          :context="context"
          :defaultColDef="defaultColDef"
          pagination
          :paginationPageSize="1000"
          :paginationPageSizeSelector="false"
          :rowData="rowData"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :sideBar="false"
          suppressDragLeaveHidesColumns
          suppressCellFocus
          suppressScrollOnNewData
          suppressContextMenu
          suppressMultiSort
          >
        </ag-grid-vue>
      </template>
    </template>
  </WidgetFrame>
</template>

<script>
import { cloneDeep } from 'lodash'
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import DateTimeCellRenderer from '@/components/Aggrid/CellRenderer/DateTime';
import PercentageCellRenderer from '@/components/Aggrid/CellRenderer/Percentage';
import LinkCellRenderer from '@/components/Aggrid/CellRenderer/Link';
import { taskService } from '@/services';

export default {
  name: 'ProjectActiveTasksWidget',
  components: {
    'ag-grid-vue': AgGridVue,
    WidgetFrame: () => import('@/components/Dashboard/WidgetFrame'),
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    dateTimeCellRenderer: DateTimeCellRenderer,
    percentageCellRenderer: PercentageCellRenderer,
    linkCellRenderer: LinkCellRenderer,
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    project:      { type: Object, default: null },
    refresh:    { type: Boolean, default: false },
    profile: {
      type: Object,
      default: null
    }
  },
  watch: {
    refresh(newValue, oldValue) {
      if (newValue) {
        this.buildData();
        this.$emit('refreshed');
      }
    }
  },
  computed: {
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>No active tasks</span>`;
    }
  },
  data: function() {
    return {
      loaded: false,
      gridOptions: null,
      gridApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
      activeTasks: []
    };
  },
  beforeMount() {
    const self = this;
    this.gridOptions = {
      onGridSizeChanged: function(event) {
        self.gridApi.autoSizeColumns(['name'], false);
      },
      onSortChanged: function(event) {
        if (event.source === 'gridOptionsChanged') {
          return; // do not process this sort changed as it does not contain the user's sort column selection
        }
        
        if (event.api.gridBodyCtrl && event.api.gridBodyCtrl.bodyScrollFeature) {
          event.api.gridBodyCtrl.bodyScrollFeature.setVerticalScrollPosition(0)
        }
        //Update column setting
        const columns = event.api.getAllDisplayedColumns()
        self.saveSelectorColumnSettings(columns, false)
      }, 
      onDragStopped: function(event) {
        const columns = event.api.getAllDisplayedColumns()
        self.saveSelectorColumnSettings(columns, false)
      }
    };
    this.columnDefs = [
      {
        headerName: this.$t('active_task.grid.name'),
        field: 'name',
        flex: 1,
        cellRenderer: 'linkCellRenderer',
        cellRendererParams: {
          value: function() {
            return this.data.name;
          },
          clicked: function(field) {
            self.$emit('editTask', {taskId: this.data.uuId, projectId: this.data.projectId, callback: null});
          },
        },
      },
      {
        headerName: this.$t('active_task.grid.path'),
        sortable: false,
        field: 'path',
        flex: 2,
      },
      {
        headerName: this.$t('active_task.grid.start'),
        field: 'startTime',
        sort: 'asc',
        flex: 1,
        cellRenderer: 'dateTimeCellRenderer'
      },
      {
        headerName: this.$t('active_task.grid.end'),
        field: 'endTime',
        flex: 1,
        cellRenderer: 'dateTimeCellRenderer'
      },
      {
        headerName: this.$t('active_task.grid.progress'),
        field: 'progress',
        minWidth: 50,
        maxWidth: 100,
        cellRenderer: 'percentageCellRenderer'
      },
    ];
    this.defaultColDef = {
      resizable: true,
      sortable: true,
      minWidth: 100,
      menuTabs: [],
      valueFormatter: (params) => params.value,
      valueParser: (params) => params.newValue
    };
    this.context = {
      componentParent: self
    };
    
    this.loadColumnSettings(this, this.profile.columns);
  },
  created() {
    this.buildData();
  },
  beforeDestory() {
    this.gridApi = null;
  },
  methods: {
    async buildData() {
      this.activeTasks = [];
      const self = this;
      const activeFilter = [
          "_and_",
          [
            ["PROJECT.TASK.progress", "gt", 0],
            ["PROJECT.TASK.progress", "lt", 1],
            ["PROJECT.TASK.taskType", "eq", 'Task']
          ]
        ];
        
        let tasksIds = await taskService.listProjectDashboard({ filter: activeFilter, limit_data: true, limit: 100 }, this.project.uuId).then(resp => {
          return resp.data;
        }).catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
        
        if (tasksIds.length !== 0) {
          const tasks = await taskService.listTasksDashboard(tasksIds.map(t => t.uuId)).then(response => {
            return response.data;
          }).catch(e => {
            // eslint-disable-next-line
            console.error(e);
          });
          
        tasks.forEach(function(task) {
          self.activeTasks.push({
            name: task.name,
            path: task.path,
            startTime: moment(task.startTime),
            endTime: moment(task.endTime),
            progress: task.progress,
            // Need these for the Edit Task modal
            uuId: task.uuId,
            projectId: self.project.uuId
          })
        });
        this.rowData = this.activeTasks;
      }
      else {
        this.rowData = [];
      }
      this.loaded = true;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.rowData = this.activeTasks;
    }, 
    saveSelectorColumnSettings(columns, skipCheck=true) {
      const getColumnDefs = (c) => {
        return {
          colId: c.colId
          , width: c.actualWidth
          , sort: c.sort != null? c.sort : null
          , sortIndex: c.sortIndex != null? c.sortIndex : null
        }
      }
          
      const newColumns = columns.filter(c => c.colId != 'rowSelector').map(c => getColumnDefs(c))
      const oldColumns = this.columns
      if (skipCheck) {
        this.profile.columns = newColumns;
        this.$emit('saveWidget');
        return
      }

      let hasChanged = false
      if (oldColumns == null) {
        hasChanged = true
      } else if (oldColumns.length != newColumns.length) {
        hasChanged = true
      } else {
        for (const [index, col] of oldColumns.entries()) {
          if (col.colId != newColumns[index].colId || 
              col.width != newColumns[index].width ||
              col.sort != newColumns[index].sort ||
              col.sortIndex != newColumns[index].sortIndex) {
            hasChanged = true
            break
          }
        }
      }
      if (hasChanged) {
        this.profile.columns = newColumns;
        this.$emit('saveWidget');
      }
    },
    loadColumnSettings(target, columns) {
      if (target == null || columns == null || columns.length == 0) {
        return
      }
      
      // order the columns based upon the order in 'columns'
      // 0 index column is reserved for rowSelector
      let idx = 1
      columns.forEach(function(col) {
        const index = target.columnDefs.findIndex((c) => c.colId === col.colId || c.field === col.colId)
        if (index !== -1) {
          target.columnDefs.splice(idx++, 0, target.columnDefs.splice(index, 1)[0])
        }
      })
      
      for (const column of target.columnDefs) {
        const setting = columns.filter(c => c.colId === column.colId || c.colId === column.field)
        if (setting.length === 0) {
          if (column.colId != 'rowSelector') {
            column.hide = true
            column.sort = null //reset to null to clean up previous view value
            column.sortIndex = null //reset to null to clean up previous view value
          }
        }
        else {
          column.hide = false
          column.width = setting[0].width
          column.sort = setting[0].sort
          column.sortIndex = setting[0].sortIndex
        }
      }

      //Rearrange sort Index if necessary
      const columnsWithSortIndex = target.columnDefs.filter(i => i.sortIndex != null);
      if (columnsWithSortIndex.length > 0) {
        columnsWithSortIndex.sort((a, b) => {
          if (a.sortIndex < b.sortIndex) {
            return -1;
          } else if (a.sortIndex > b.sortIndex) {
            return 1;
          }
          return 0;
        })
        
        for (let i = 0, len = columnsWithSortIndex.length; i < len; i++) {
          columnsWithSortIndex[i].sortIndex = i;
        }
      }

      if (target.gridApi != null && !target.gridApi.isDestroyed()) {
        target.gridApi.setGridOption('columnDefs', [])
        target.gridApi.setGridOption('columnDefs', cloneDeep(target.columnDefs))

      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .center-text {
    text-align: center;
    margin: auto;
  }
</style>
