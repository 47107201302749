<template>
    <a href="#" @click="clickHandler">{{ text }}</a>
</template>


<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'LinkCellRenderer',
  data() {
    return {
      value: null
    }
  },
  beforeMount() {

  },
  computed: {
    text() {
      return this.params.value();
    }
  },
  methods: {
    clickHandler(e) {
      this.params.clicked(this.params.data.uuId, e);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
  },
})
</script>